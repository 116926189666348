
/**
 * This is rendered inside each Vue Formulate field.
 * See formulate.config.js
 */
import { bool, shape } from 'vue-types';

export default {
    inheritAttrs: false,
    props: {
        context: shape({
            hasValue: bool().def(false),
            isValid: bool().def(false),
        }).loose,
    },
};
