
/**
 * Standalone ValidationIcon.
 */
import { bool } from 'vue-types';

export default {
    inheritAttrs: false,
    props: {
        showValidationErrors: bool().def(true),
        hasValue: bool().def(false),
        isValid: bool().def(false),
    },
    computed: {
        showValid() {
            return this.hasValue && this.isValid;
        },
        showError() {
            return this.showValidationErrors && !this.isValid;
        },
    },
};
